import React from 'react';
import DashTile from './DashTile.js';
import './CityDashboard.css';

// svozovy plan: https://www.mestopacov.cz/assets/File.ashx?id_org=11721&id_dokumenty=2643


function CityDashboard(props) {

  const width = Number(props.width)
  const height = Number(props.height)
  const smlouvy = props.smlouvy

  let colCount;
  if (width < 751) colCount = 1
  else if (width < 1201) colCount = 2
  else colCount = 4

  const colWidthCss = `${0.9 * 100 / colCount}vw`

  const content = [

    {
      gridLbl: 'element',
      title: 'Poplatek za odvoz odpadu',
      subtitle: 'k 1.1.2021',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="trvale hlášená osoba" value="700" unit="Kč/rok"/>
          <DashTile title="neobývaná nemovitost" value="700" unit="Kč/rok"/>
        </p>,
        <p>Splatnost: <span className='splatnost'>30.6.</span></p>,
        <p>Svoz od domu (door-to-door) <span className='splatnost'>od 1.4.2021</span></p>,        
        <p>Zdroj: <a href="https://www.mestopacov.cz/assets/File.ashx?id_org=11721&id_dokumenty=7018">vyhláška č. 3/2020</a></p>
      ]
    },

    {
      gridLbl: 'element',
      title: 'Vodné/stočné',
      subtitle: 'k 1.1.2021',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="Pacov, Roučkovice" value="81" unit={<span>Kč/m<sup>3</sup></span>}/>
          <DashTile title="Velká Rovná, Zhoř, Bedřichov, Jetřichovec" value="49" unit={<span>Kč/m<sup>3</sup></span>}/>
          <DashTile title="Hrádek, Malá Rovná" value="35" unit={<span>Kč/m<sup>2</sup></span>}/>
          <DashTile title="Salačova Lhota, Důl, Velká Chyška, Zhořec, Nová Ves, Bratřice" value="25" unit={<span>Kč/m<sup>3</sup></span>}/>
        </p>,
        <p>Zdroj: <a href="https://www.mestopacov.cz/assets/File.ashx?id_org=11721&id_dokumenty=7082">Usnesení rady č. 201 ze 21.12.2020</a></p>
      ]
    },


    {
      gridLbl: 'element',
      title: 'Počet obyvatel',
      subtitle: 'k 1.1.2020',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="celkem obyvatel" value="4717" />
          <DashTile title="průměrný věk" value="44" unit="let" />
          <DashTile title="počet mužů" value="2303" />
          <DashTile title="průměrný věk mužů" value="43,4" unit="let" />
          <DashTile title="počet žen" value="2414" />
          <DashTile title="průměrný věk žen" value="46,4" unit="let" />
        </p>,
        <p>Zdroj: <a href="https://www.czso.cz/csu/czso/pocet-obyvatel-v-obcich-k-112019">ČSÚ</a></p>
      ]
    },



    {
      gridLbl: 'element',
      title: 'Poplatek za psa',
      subtitle: 'k 1.1.2021',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="první pes" value="300" unit={<span>Kč</span>}/>
          <DashTile title="druhý a další pes" value="450" unit={<span>Kč</span>}/>
          <DashTile title="první pes v bytovém domě" value="700" unit={<span>Kč</span>}/>
          <DashTile title="druhý a další pes v bytovém domě" value="1050" unit={<span>Kč</span>}/>
          <DashTile title="první pes mimo Pacov" value="60" unit={<span>Kč</span>}/>
          <DashTile title="druhý a další pes mimo pacov" value="90" unit={<span>Kč</span>}/>
        </p>,
        <p>Zdroj: <a href="https://www.mestopacov.cz/assets/File.ashx?id_org=11721&id_dokumenty=5295">vyhláška č. 5/2019</a></p>
      ]
    },

    {
      gridLbl: 'element',
      title: <span>Poplatek za psa <b>pro důchodce</b></span>,
      subtitle: 'k 1.1.2021',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="první pes" value="100" unit={<span>Kč</span>}/>
          <DashTile title="druhý a další pes" value="150" unit={<span>Kč</span>}/>
          <DashTile title="první pes v bytovém domě" value="200" unit={<span>Kč</span>}/>
          <DashTile title="druhý a další pes v bytovém domě" value="300" unit={<span>Kč</span>}/>
          <DashTile title="první pes mimo Pacov" value="60" unit={<span>Kč</span>}/>
          <DashTile title="druhý a další pes mimo pacov" value="90" unit={<span>Kč</span>}/>
        </p>,
        <p>Zdroj: <a href="https://www.mestopacov.cz/assets/File.ashx?id_org=11721&id_dokumenty=5295">vyhláška č. 5/2019</a></p>
      ]
    },



    {
      gridLbl: 'element',
      title: 'Poplatek za využívání veřejného prostranství',
      subtitle: 'k 1.1.2020',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="výkopy, stavby" value="1" unit={<span>Kč/m<sup>2</sup></span>}/>
          <DashTile title="poskytování služeb" value="2" unit={<span>Kč/m<sup>2</sup></span>}/>
          <DashTile title="prodej" value="20" unit={<span>Kč/m<sup>2</sup></span>}/>
          <DashTile title="prodej o pouti" value="100" unit={<span>Kč/m<sup>2</sup></span>}/>
          <DashTile title="řemeslný prodej o pouti" value="30" unit={<span>Kč/m<sup>2</sup></span>}/>
          <DashTile title="umístění atrakcí o pouti" value="??" unit={<span>Kč/m<sup>2</sup></span>}/>
        </p>,
        <p>Zdroj: <a href="https://www.czso.cz/csu/czso/pocet-obyvatel-v-obcich-k-112019">ČSÚ</a></p>
      ]
    },


    {
      gridLbl: 'element',
      title: 'Koeficient daně z nemovitosti',
      subtitle: '',
      text: [
        <p style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap',  alignContent: 'left' }}>
          <DashTile title="Pacov" value="1,6"/>
          <DashTile title="Bedřichov, Jetřichovec, Roučkovice, Velká Rovná, Zhoř" value="1,0"/>
        </p>,
        <p>Zdroj: <a href="https://www.mestopacov.cz/assets/File.ashx?id_org=11721&id_dokumenty=2595">vyhláška č. 1/2008</a></p>
      ]
    },



    {
      gridLbl: 'element',
      title: 'Výjímky z nočního klidu',
      subtitle: 'pro rok 2021',
      text: [
        <p><b>Kvůli Covid19 omezením není vyhláška vydána</b></p>,
      ]
    },

    {
      gridLbl: 'element',
      title: 'Úřední hodiny',
      subtitle: 'městský úřad Pacov',
      text: [
        <img src={process.env.PUBLIC_URL + '/uredni-hodiny.png'} style={{ maxWidth: colWidthCss }} alt="schéma úředních hodin úřadu" />,
        <p>Zdroj: <a href="https://www.mestopacov.cz/">webové stránky města</a></p>
      ]
    },
    {
      gridLbl: 'element',
      title: 'Školství',
      subtitle: 'přehled organizací',
      text: [
        'Mateřská škola Za Branou',
        'Mateřská škola Jatecká',
        'Církevní mateřská škola Jonáš',
        'Základní škola',
        'Základní umělecká škola',
        'Gymnázium',
        <p>Zdroj: <a href="https://www.mestopacov.cz/skolstvi/ms-1109/p1=1109">web města</a></p>
      ]
    },

    {
      gridLbl: 'element',
      title: 'Sociální služby',
      subtitle: 'přehled organizací',
      text: [
        'Dům sociálních služeb',
        'Centrum pro seniory',
        'Farní charita',
        'Občanská poradna',
        <p>Zdroj: <a href="https://www.mestopacov.cz/katalog%2Dsocialnich%2Dsluzeb/ms-2640/p1=2640">web města</a></p>
      ]
    },
/*
    {
      gridLbl: 'element',
      title: 'Souhrnné zadlužení',
      subtitle: 'cizí zdroje města a finance vázane v dotacích',
      text: [
        <img src={process.env.PUBLIC_URL + '/cizi-zdroje-celkem.png'} style={{ maxWidth: colWidthCss }} alt="graf míry zadlužení" />,
        <p>Zdroj: <a href="https://monitor.statnipokladna.cz/analyza/##query=uq_41a67636-0eed-40dd-88a7-556f3c767af3">monitor státní pokladnny</a></p>
      ]
    },

*/

    {
      gridLbl: 'element',
      title: 'Poslední usnesení rady',
      subtitle: 'přehled',
      text: [
        'rada města jedná pravidleně každých 14 dnů'
      ]
    },

    {
      gridLbl: 'element',
      title: 'Poslední usnesení zastupitelstva',
      subtitle: 'přehled',
      text: [
        'připravujeme',
      ]
    },

    {
      gridLbl: 'element',
      title: 'Příští zasedání zastupitelstva',
      subtitle: 'odhad',
      text: [
        'příští zasedání zastupitelstva by mělo proběhnout nejpozději 16.3.2021'
      ]
    },

    {
      gridLbl: 'element',
      title: 'Novinky v projektech města',
      subtitle: 'poslední události v připravovaných a běžících projektech',
      text: [
        'připravujeme'
      ]
    },
    {
      gridLbl: 'element',
      title: 'Základní kontaktní údaje',
      subtitle: 'kontaktní údaje města',
      text: [
        'Městský úřad Pacov, nám. Svobody 1, 395 01 Pacov',
        <p>tel: <a href="tel:+420565455111">565 455 111</a>, <a href="tel:+420565455133">565 455 133 (zámek)</a>, <a href="tel:+420565455155">565 455 155 (radnice)</a></p>,
        <p>email: <a href="mailto:posta@mestopacov.cz">posta@mestopacov.cz</a></p>,
        'datová schránka: xbtbhcm',
        'IČO: 00248789 , DIČ: CZ00248789',
        <p><a href="https://www.mestopacov.cz">https://www.mestopacov.cz</a></p>,
        <p>Zdroj: <a href="https://www.mestopacov.cz/vismo/o_utvar.asp?id_org=11721&id_u=10">web města</a></p>
      ]
    },

  ]

  if (smlouvy) {
    const radky = smlouvy.map((sml, idx)=>{
      const datumUzavreni = sml.datumUzavreni.substring(0,10)
      let hodnota = "N/A" 
      if (sml.hodnotaVcetneDph) {
        hodnota = `${sml.hodnotaVcetneDph}` 
      } else if (sml.hodnotaBezDph) {
        hodnota = `${sml.hodnotaBezDph}` 
      } else {
        hodnota = "Hodnota neznámá"
      }




      return (
        <span className='smlouva' key={idx}>
        <a className="sml-ikona" href={`https://www.hlidacstatu.cz/Detail/${sml.identifikator.idVerze}`}><i className="material-icons" style={{ color: "gray", cursor: "pointer" }} title="detial">search</i> </a>
        <span className="sml-column1">
          <span className="sml-emp">{datumUzavreni}</span>
          <span>{hodnota}</span>
        </span>
        <span className='sml-column2'>
          <span className="sml-emp">{sml.Prijemce[0].nazev}</span>
          <span>{sml.predmet}</span>
        </span>
      </span>
    )})
    const smlouvyElement= {
      gridLbl: 'element',
      title: 'Poslední uzavřené smlouvy',
      subtitle: 'přehled',
      text: [
        ...radky,
        <p>Zdroj: <a href="https://www.hlidacstatu.cz/hledat?Q=ico%3A00248789">Hlídač státu</a></p>
      ]
    }
    content.push(smlouvyElement)
  }


  const cols = []
  for (var colIdx = 0; colIdx < colCount; colIdx++) {
    cols[colIdx] = []
  }

  const items = content.forEach((v, idx) => {
    let colIdx = idx % colCount;
    const text = v.text.map((t, txtIdx) => (typeof (t) === "string" ? <p key={txtIdx}>{t}</p> : t))
    cols[colIdx].push(
      <div className={v.gridLbl} key={idx} >
        <div className="element-heading">
          <div className="element-titles">
            <h1>{v.title}</h1>
            <h2>{v.subtitle}</h2>
          </div>
          <div className="element-icons">
            <span><i className="material-icons" style={{ color: "gray", cursor: "pointer" }} title="oblibené">star_border</i></span>
            <span><i className="material-icons" style={{ color: "gray", cursor: "pointer" }} title="líbí se mi">favorite_border</i></span>
            <span><i className="material-icons" style={{ color: "gray", cursor: "pointer" }} title="sledovat">visibility</i></span>
          </div>
        </div>
        {text}
      </div>
    )
  })

  const colsElms = []
  for (var colIdx = 0; colIdx < colCount; colIdx++) {
    colsElms.push(<div className="contentColumn" style={{ "grid-column-start": colIdx, "grid-column-end": colIdx + 1 }}>{cols[colIdx]}</div>)
  }

  return (
    <div className="wrapper">
      <div className="container">
        <div className="title" style={{ "display": "flex", "alignItems": "center" }}>
          <div style={{ 'flexGrow': '1' }}>
            <h1>Smart.Pacov.City</h1>
            <h2>přehled praktických informací o městě Pacově</h2>
            <p>Údaje jsou platné pro aktuální rok 2021  . </p>
          </div>
          <div style={{ 'flexGrow': 'none' }}>
            <img src="https://pacov.city/img/social-logo.png" style={{ 'maxHeight': '8ex' }} alt="logo" />
          </div>
        </div>

        {colsElms}
      </div>


    </div>
  );
}

export default CityDashboard;
