import React from 'react';


function DashTile({title,value,unit}) {
    return (
        <div style={{ borderRadius:'5px', border: 'thin gray solid', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',   width: '120px', height: '120px', padding: '5px', margin: '5px'}}>
            <span style={{ alignSelf: 'flex-start', textAlign: 'left' }}>{title}</span>
            <span style={{ alignSelf: 'flex-end', alignItems: 'baseline', display: 'flex', flexDirection: 'row', margin: '5px', flexWrap:'wrap' }}>
                <span style={{ fontWeight: 'bold', fontSize: '200%', marginRight: '5px' }}>{value}</span>
                <span style={{ fontSize: '120%' }}>{unit}</span> 
            </span>
        </div>
    )
}

export default DashTile;
